import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wet chemicals`}</h4>
    <p>{`Wet chemicals, such as sulfuric acid, isopropyl alcohol, ammonium hydroxide, phosphoric acid, nitric acid, hydrochloric acid, and acetic acid, are widely used in semiconductor manufacturing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      